<template>
  <div class="row">
    <div class="col-lg-9 col-md-9 col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              <h4>Academic Qualification Check</h4>
              <div class="underline"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="text-info">Personal details</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="firstName"
                  autocomplete="off_firstName"
                  placeholder=" "
                  v-model="step1.first_name"
                />
                <label class="floatingLabel" for="firstName">Given name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="middleName"
                  autocomplete="off_middleName"
                  placeholder=" "
                  v-model="step1.middle_name"
                />
                <label class="floatingLabel" for="firstName">Middle name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="lastName"
                  autocomplete="off_lastName"
                  placeholder=" "
                  v-model="step1.last_name"
                />
                <label class="floatingLabel" for="lastName">Family name</label>
                <div class="invalid-feedback col-12" v-if="errors.last_name">
                  {{ errors.last_name[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <b-form-checkbox
                v-if="step1.first_name == '' || step1.first_name == null"
                id="checkbox-1"
                name="checkbox-1"
                value="No"
                unchecked-value="Yes"
                v-model="step1.have_a_given_name"
              >
                Does not have a given name
              </b-form-checkbox>
              <div
                class="invalid-feedback col-12"
                v-if="errors.have_a_given_name"
              >
                {{ errors.have_a_given_name[0] }}
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <b-form-checkbox
                v-if="step1.middle_name == '' || step1.middle_name == null"
                id="checkbox-2"
                name="checkbox-2"
                value="No"
                unchecked-value="Yes"
                v-model="step1.have_a_middle_name"
              >
                Does not have a middle name
              </b-form-checkbox>
              <div
                class="invalid-feedback col-12"
                v-if="errors.have_a_middle_name"
              >
                {{ errors.have_a_middle_name[0] }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="date"
                  id="dateOfBirth"
                  autocomplete="off_dateOfBirth"
                  placeholder=" "
                  v-model="step1.date_of_birth"
                />
                <label class="floatingLabel" for="dateOfBirth"
                  >Date of birth</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.date_of_birth"
                >
                  {{ errors.date_of_birth[0] }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <p class="text-info">Academic qualification</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12 text-right mb-3">
              <appModalAcademicQualificationNew
                :projectId="projectId"
                :productId="5"
                :propsData="getAcademicQualification"
              >
              </appModalAcademicQualificationNew>
            </div>

            <div class="col-12 table-responsive">
              <table class="table table-bordered table-striped table-sm m-0">
                <thead>
                  <tr>
                    <th>
                      <p class="mb-0">Given Name(s)</p>
                      <p class="fs-800 mb-0 fw-400">(Qualification held in)</p>
                    </th>
                    <th>
                      <p class="mb-0">Family Name(s)</p>
                      <p class="fs-800 mb-0 fw-400">(Qualification held in)</p>
                    </th>
                    <th>Qualification obtained</th>
                    <th>
                      <p class="mb-0">Name of organisation / institution</p>
                      <p class="fs-800 mb-0 fw-400">
                        (Issued the qualification)
                      </p>
                    </th>

                    <th>Date qualification obtained</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in step2.academic_qualification_trans"
                    :key="index"
                  >
                    <td>{{ row.first_name }}</td>
                    <td>{{ row.last_name }}</td>
                    <td>{{ row.qualification_obtained }}</td>
                    <td>{{ row.name_of_organisation }}</td>
                    <td>{{ row.date_qualification_obtained }}</td>
                    <td>
                      <div>
                        <appModalAcademicQualificationEdit
                          :projectId="projectId"
                          :productId="5"
                          :propsData="row"
                          @fnCallRefresh="fnCallRefresh"
                          class="d-inline"
                        >
                        </appModalAcademicQualificationEdit>
                        <a
                          href="javascript: void(0)"
                          class="p-2 d-inline"
                          @click="fnDeleteQualification(row.id)"
                          ><i class="far fa-times-circle text-info"></i
                        ></a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 text-right">
              <router-link
                v-if="loginUserObj.role_id == 9"
                class="btn btn-secondary btn-sm mr-2"
                :to="{ name: 'dashboardCC' }"
                >Cancel</router-link
              >
              <button
                v-if="isSubmit == true"
                class="btn btn-info btn-sm"
                @click="fnUpdate()"
              >
                <b-spinner small v-if="loading" class="mr-2"></b-spinner>
                Submit
              </button>
              <button
                v-if="isSave == true"
                class="btn btn-info btn-sm"
                @click="fnSave()"
              >
                <b-spinner small v-if="loading" class="mr-2"></b-spinner>Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-12">
      <div class="card h-overflow">
        <div class="card-body">
          <appStep2Ins></appStep2Ins>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as chkAcademicQualificationService from "../../services/chkAcademicQualification.service";

import * as messageService from "../../services/message.service";
import appStep2Ins from "../policeCheck/step2Ins.vue";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";
import appModalAcademicQualificationNew from "./ModalAcademicQualificationNew.vue";
import appModalAcademicQualificationEdit from "./ModalAcademicQualificationEdit.vue";

export default {
  name: "CheckAcademicQualificationCom",
  props: ["mode", "projectId", "productId", "userId"],
  components: {
    appStep2Ins,
    appModalAcademicQualificationNew,
    appModalAcademicQualificationEdit,
  },
  data() {
    return {
      loginUserObj: {},
      errors: {},
      stateOfLicence: "",
      loading: false,
      step1: {
        gender_code: "M",
        first_name: "",
        middle_name: "",
        last_name: "",
        date_of_birth: "",
        country_of_birth_code: "Australia",
        email: "",
        mobile_no: "",
        have_a_given_name: "Yes",
        have_a_middle_name: "Yes",
        have_a_family_name: "Yes",
        unit_no: "",
        street_no: "",
        street: "",
        suburb: "",
        state_code: "",
        post_code: "",
        country_code: "",
      },
      step2: {
        qualification: [],
        status: 1,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getSettingAll",
      "getAcademicQualification",
      "getUserFromId",
    ]),

    isSubmit() {
      if (
        this.getAcademicQualification &&
        this.getAcademicQualification.status < 2 &&
        this.loginUserObj.role_id == 9
      ) {
        return true;
      }

      return false;
    },
    isSave() {
      if (
        this.getAcademicQualification &&
        this.getAcademicQualification.status > 1 &&
        this.loginUserObj.role_id != 9
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    ...mapActions(["fetchAcademicQualification", "fetchUserFromId"]),

    /**
     * Submit the Data, it will use by the corporate customer
     */
    fnUpdate: async function () {
      this.loading = true;
      try {
        const res = await chkAcademicQualificationService.updateUserInfo(
          this.userId,
          this.step1
        );

        this.step2.status = 2;
        const response = await chkAcademicQualificationService.updateStatus(
          this.projectId,
          this.step2
        );

        this.error = {};

        if (response.data.status_code == 200) {
          this.fetchAcademicQualification(this.projectId);
          this.fetchUserFromId(this.userId);

          if (this.loginUserObj.role_id == 9) {
            messageService.fnToastSuccess("Submit successfully!");
            this.$router.push({
              name: "checkSuccessfulSubmissionMsgCC",
              params: {
                productId: encodeDecodeService.cyEncrypt(this.productId),
              },
            });
          } else {
            messageService.fnToastSuccess("Saved successfully!");
          }
        } else {
          return;
        }
        this.loading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Academic Qualification",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Academic Qualification",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Academic Qualification",
              "Something wrong, please try again!"
            );
            break;
        }
        this.loading = false;
      }
    },
    /**
     * Save the Data, it will use by the Admin and his staff
     */
    fnSave: async function () {
      this.loading = true;
      try {
        const res = await chkAcademicQualificationService.updateUserInfo(
          this.userId,
          this.step1
        );

        const response = await chkAcademicQualificationService.updateStatus(
          this.projectId,
          this.step2
        );

        this.error = {};

        if (response.data.status_code == 200) {
          this.fetchAcademicQualification(this.projectId);
          this.fetchUserFromId(this.userId);

          if (this.loginUserObj.role_id == 9) {
            messageService.fnToastSuccess("Submit successfully!");
            this.$router.push({
              name: "checkSuccessfulSubmissionMsg",
              params: {
                productId: encodeDecodeService.cyEncrypt(this.productId),
              },
            });
          } else {
            messageService.fnToastSuccess("Saved successfully!");
          }
        } else {
          return;
        }
        this.loading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Academic Qualification",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Academic Qualification",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Academic Qualification",
              "Something wrong, please try again!"
            );
            break;
        }
        this.loading = false;
      }
    },
    fnDeleteQualification: async function (id) {
      try {
        this.errors = {};

        await chkAcademicQualificationService.deleteTrans(id);

        this.fetchAcademicQualification(this.projectId);
        messageService.fnToastSuccess(
          "Academic Qualification - Successfully deleted"
        );
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Academic Qualification",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Academic Qualification",
              "Something wrong"
            );
            break;
        }
      }
    },

    fnCallRefresh: function () {
      this.fetchUserFromId(this.userId);
      this.fetchAcademicQualification(this.projectId);
    },
  },

  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchUserFromId(this.userId);
    this.fetchAcademicQualification(this.projectId);
  },

  watch: {
    getUserFromId(newVal) {
      Object.assign(this.step1, newVal);
    },
    getAcademicQualification(newVal) {
      Object.assign(this.step2, newVal);
    },
  },
};
</script>
