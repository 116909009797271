<template>
  <div class="">
    <appCheckAcademicQualification
      :projectId="projectId"
      :productId="productId"
      :userId="userId"
    ></appCheckAcademicQualification>
  </div>
</template>
<script>
import appCheckAcademicQualification from "../../components/checkAcademicQualification/checkAcademicQualification";
import * as encodeDecodeService from "../../services/encodeDecode.service";
export default {
  name: "CheckAcademicQualification",

  components: {
    appCheckAcademicQualification,
  },
  data() {
    return {
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
      productId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
    };
  },
};
</script>