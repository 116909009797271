<template>
  <div>
    <button
      class="btn btn-info btn-sm"
      data-toggle="tooltip"
      data-placement="top"
      title="Add qualification"
      @click="fnShowModal()"
    >
      Add Qualification
    </button>

    <b-modal
      ref="academicQualificationModal"
      size="xl"
      hide-footer
      title="Academic qualification"
    >
      <div class="d-block">
        <div class="alert alert-danger mb-5 col-12" v-if="arrErr.length">
          <p class="fs-800 mb-0" v-for="(item, index) in arrErr" :key="index">
            {{ item }}
          </p>
        </div>
        <form v-on:submit.prevent="fnCreate">
          <div class="row">
            <div class="col-lg-8 col-md-8 col-12">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-group">
                    <label for="firstName"
                      >First name
                      <span class="fs-700 fw-400">
                        (Qualification held in)</span
                      ></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First name"
                      v-model="userData.first_name"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-group">
                    <label for="lastName"
                      >Family name<span class="fs-700 fw-400">
                        (Qualification held in)</span
                      ></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="lastName"
                      placeholder="Family name"
                      v-model="userData.last_name"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-group">
                    <label for="lastName">Qualification obtained</label>
                    <input
                      type="text"
                      class="form-control"
                      id="lastName"
                      placeholder="Qualification obtained"
                      v-model="userData.qualification_obtained"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="form-group">
                    <label class="">Date qualification obtained</label>
                    <input
                      class="form-control"
                      type="date"
                      autocomplete="off_date_of_change"
                      placeholder="Date qualification obtained"
                      v-model="userData.date_qualification_obtained"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <label for="lastName"
                  >Name of organisation/institution<span class="fs-700 fw-400">
                    (Issued the qualification)</span
                  ></label
                >
                <textarea
                  type="text"
                  class="form-control"
                  placeholder="Name of Organisation"
                  v-model="userData.name_of_organisation"
                  rows="7"
                />
              </div>
            </div>
          </div>

          <div class="text-right">
            <button
              type="button"
              class="btn btn-secondary btn-sm mr-2"
              @click="hideModal"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-info btn-sm">
              <b-spinner small v-if="isLoadingSave" class="mr-2"></b-spinner
              >Save
            </button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as chkAcademicQualificationService from "../../services/chkAcademicQualification.service";

export default {
  name: "ModalAcademicQualificationNew",
  props: ["projectId", "productId", "status", "academic_qualification"],
  components: {},
  data() {
    return {
      isLoadingSave: false,
      errors: {},
      arrErr: [],
      userData: {
        project_id: "",
        first_name: "",
        last_name: "",
        qualification_obtained: "",
        date_qualification_obtained: "",
        name_of_organisation: "",
        status: 1,
      },
    };
  },
  computed: {},
  methods: {
    ...mapActions(["fetchAcademicQualification", "fetchUserFromId"]),
    fnShowModal() {
      this.showModal();
    },
    hideModal() {
      this.$refs.academicQualificationModal.hide();
    },
    showModal() {
      this.$refs.academicQualificationModal.show();
    },

    fnValidation() {
      this.arrErr = [];
      if (this.userData.first_name == "" || this.userData.first_name == null) {
        this.arrErr.push("First name is required");
      }

      if (this.userData.last_name == "" || this.userData.last_name == null) {
        this.arrErr.push("Last name is required");
      }

      if (
        this.userData.qualification_obtained == "" ||
        this.userData.qualification_obtained == null
      ) {
        this.arrErr.push("Qualification obtained is required");
      }

      if (
        this.userData.date_qualification_obtained == "" ||
        this.userData.date_qualification_obtained == null
      ) {
        this.arrErr.push("Date qualification obtained is required");
      }

      if (
        this.userData.name_of_organisation == "" ||
        this.userData.name_of_organisation == null
      ) {
        this.arrErr.push("Name of organisation / institution is required");
      }
      return this.arrErr;
    },

    fnCreate: async function () {
      this.isLoadingSave = true;
      this.errors = {};
      this.fnValidation();

      if (this.arrErr.length > 0) {
        return;
      }

      this.userData.project_id = this.projectId;
      try {
        await chkAcademicQualificationService.add(this.userData);

        this.fetchAcademicQualification(this.projectId);
        this.hideModal();
        this.isLoadingSave = false;

        messageService.fnToastSuccess(
          "Academic Qualification - Successfully added"
        );

        this.userData = {
          first_name: "",
          last_name: "",
          qualification_obtained: "",
          date_qualification_obtained: "",
          name_of_organisation: "",
          status: 1,
        };
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Academic Qualification",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Academic Qualification",
              "Something wrong"
            );
            break;
        }

        console.log("Something wrong - fnCreate", error);
      }
    },
  },
  created() {},
};
</script>
